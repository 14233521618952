import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import PreviewedBlogCard from '../components/previewedBlogCard'
import styled from 'styled-components'
import BlogCard from '../components/blogCard'
import { FlexContainer } from '../elements'
import AnimatedContent from '../components/animatedContent'

const BlogPage = ({ data, className }) => {
  const [previewedBlog, setPreviewedBlog] = useState(null)
  const blogPages = data['allMarkdownRemark']['edges']
  const isPreviewed = blogPage => {
    return blogPage.node.frontmatter.title
      .toLowerCase()
      .includes(
        data.markdownRemark.frontmatter.previewedArticleTitle.toLowerCase()
      )
  }

  useEffect(() => {
    const getPreviewedBlog = blogPages.find(blogPage => {
      return isPreviewed(blogPage)
    })
    setPreviewedBlog(getPreviewedBlog)
  }, [data, blogPages])

  return (
    <Layout lang="en" disableArabicLanguage={true}>
      <SEO title={`Melior Blog`} />
      <div className={className}>
        <AnimatedContent>
          <FlexContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
            width="60%"
            maxWidth="650px"
            flexWrap="wrap"
            minHeight="30vh"
            alignSelf="center"
            margin="0px auto"
            className="smallScreen"
          >
            <h1 className="main-heading text-center" dir="auto">
              Welcome to Melior Blog
            </h1>
            <p className="subtitle text-center" dir="auto">
              We share interesting healthcare stories that inspire the world.
              Our aim is to make all healthcare{' '}
              <span className="subtitle-standout">patient</span> centered.
            </p>
          </FlexContainer>
          {previewedBlog && <PreviewedBlogCard previewedBlog={previewedBlog} />}
          <div className="line-separator" />
          <FlexContainer
            justifyContent="space-between"
            className="blog-cards"
            flexWrap="wrap"
          >
            {blogPages.map(
              blogPage => !isPreviewed(blogPage) && <BlogCard blog={blogPage} />
            )}
          </FlexContainer>
        </AnimatedContent>
      </div>
    </Layout>
  )
}

export default styled(BlogPage)`
  margin-top: 15vh;
  position: relative;
  @media (max-width: 425px) {
    margin: 15vh auto;
  }
  .line-separator {
    margin: 30px 0px;
    width: 100%;
    height: 1px;
    background-color: #3a3b3c;
  }
  .blog-cards {
    margin: 40px 0px;
  }
  .smallScreen {
    padding: 0;
    width: 100%;
    z-index: 2;
  }
  .text-center {
    text-align: center;
  }
  .subtitle {
    font-size: 1.3rem;
  }
  .subtitle-standout {
    // color: ${props => props.theme.colors.yellowMelior};
    font-family: 'MyriadProRegular';
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
`

export const settingPageQuery = graphql`
  query SettingsPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/settingsPage.md/" }) {
      frontmatter {
        previewedArticleTitle
      }
    }
    allMarkdownRemark(filter: { frontmatter: { hidden: { eq: false } } }) {
      edges {
        node {
          frontmatter {
            hidden
            title
            subtitle
            date
            description
            authorName
            featuredImage
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
