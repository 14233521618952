import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from '../elements'

const BlogCard = ({ className, blog }) => {
  const {
    title,
    subtitle,
    date,
    description,
    authorName,
    featuredImage,
  } = blog.node.frontmatter

  const { slug } = blog.node.fields

  return (
    <Link to={`/blog${slug}`} className={className}>
      <FlexContainer
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="column"
      >
        <img className="featured-image" src={featuredImage} />
        <h2 className="text title">{title}</h2>
        <h4 className="text description">{description}</h4>
      </FlexContainer>
    </Link>
  )
}
export default styled(BlogCard)`
  color: inherit;
  text-decoration: inherit;
  display: inherit;
  width: 30%;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #aaaaaa;
  margin: 10px;
  @media (max-width: 425px) {
    width: 90%;
    margin: 20px auto;
  }
  &:hover {
    box-shadow: 8px 8px 15px #aaaaaa;
  }

  .featured-image {
    background-color: #aaaaaa;
    height: 160px;
    width: auto;
    border-radius: 10px;
    margin: 0 auto;
  }

  .title {
    font-family: 'Helvetica-Medium';
    font-size: 1rem;
    text-align: start;
    font-weight: 400;
    margin: 20px 10px 5px 10px;
  }
  .description {
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 20px;
    margin: 5px 10px 20px 10px;
  }
`
