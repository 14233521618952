import React from 'react'
import styled, {
    keyframes
} from "styled-components"

const spin = keyframes `
    to{
      transform: rotate(0deg);
    }
    from{
      transform: rotate(360deg);
    }
`

const Gear = styled.div `      
  position: absolute;
  top:  ${props => props.top ? props.top : ""};
  bottom:  ${props => props.bottom ? props.bottom : ""};
  left:  ${props => props.left ? props.left : ""};
  right:  ${props => props.right ? props.right : ""};
  z-index: -3;

  .gear{
    position: relative;
    width: ${props => props.size ?  `${props.size}px` : ""};
    height: ${props => props.size ? `${props.size}px` : ""};
    margin: auto;
    background: ${props => props.theme.colors.gearGrey};
    border-radius: 50%;
    animation-name: ${spin};
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .gear .center{
    position: absolute;
    top: ${props => props.size ? `${props.size/4}px` : ""} ;
    left: ${props => props.size ? `${props.size/4}px` : ""};
    z-index: -1;
    width: ${props => props.size ? `${props.size/2}px` : ""};
    height: ${props => props.size ? `${props.size/2}px` : ""};
    background: ${props => props.theme.colors.white};
    border-radius: 50%;
  }

  .tooth{
    position: absolute;
    top: ${props => props.size ? `-${props.size/8}px` : ""};
    left: ${props => props.size ? `${props.size/2 - props.size/8}px` : ""};
    z-index: -2;
    width: ${props => props.size ? `${props.size/4}px` : ""};
    height: ${props => props.size ? `${props.size/1 + props.size/4}px` : ""};
    background: ${props => props.theme.colors.gearGrey};
  }
  .tooth:nth-child(2){
    transform: rotate(45deg);
  }
  .tooth:nth-child(3){
    transform: rotate(90deg);
  }
  .tooth:nth-child(4){
    transform: rotate(135deg);
  }
  @media(max-width: 770px ) {
      top: ${props => props.small_top ? props.small_top : ""};
      bottom: ${props => props.small_bottom ? props.small_bottom : ""};
      right: ${props => props.small_right ? props.small_right : ""};
      left: ${props => props.small_left ? props.small_left : ""};
  }

`
const gear = ({size, top, left, bottom, right, small_top, small_bottom, small_left, small_right}) => {
    return (
        <Gear size={size} top={top} left={left} right={right} bottom={bottom} small_top={small_top} small_bottom={small_bottom} small_left={small_left} small_right={small_right}>
            <div className="gear">
                <div className="center"></div>
                <div className="tooth"></div>
                <div className="tooth"></div>
                <div className="tooth"></div>
                <div className="tooth"></div>
            </div>
        </Gear>
    )
}

export default gear;