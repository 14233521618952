import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from '../elements'

const PreviewedBlogCard = ({ className, previewedBlog }) => {
  const {
    title,
    subtitle,
    date,
    description,
    authorName,
    featuredImage,
  } = previewedBlog.node.frontmatter

  const { slug } = previewedBlog.node.fields

  return (
    <Link className={className} to={`/blog${slug}`}>
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <img alt={title} className="featured-image" src={featuredImage} />
        <FlexContainer
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className="featured-info"
        >
          <h2 className="title">{title}</h2>
          <h3 className="subtitle">{subtitle}</h3>
          <h4 className="description">{description}</h4>
        </FlexContainer>
      </FlexContainer>
    </Link>
  )
}
export default styled(PreviewedBlogCard)`
  text-decoration: inherit;
  color: inherit;
  margin: 5vh 0;
  .featured-image {
    width: 46%;
    height: auto;
    border-radius: 10px;
    @media (max-width: 425px) {
      width: 90%;
      margin: auto;
    }
  }
  .featured-info {
    margin: 20px 20px;
    width: 46%;
    @media (max-width: 425px) {
      width: 90%;
      margin: 20px auto;
      text-align: center;
      align-items: center;
    }
  }

  .title {
    font-family: 'Helvetica-Medium';
    text-align: start;
    margin-bottom: 5px;
    font-weight: 400;
  }
  .subtitle {
    font-weight: 400;
    margin-bottom: 20px;
  }
  .description {
    font-weight: 400;
    margin-bottom: 0;
  }
`
