import React from 'react'
import PropTypes from 'prop-types'
import { FilledCircle, OutlineCircle } from '../elements'
import Gear from '../components/gear'
const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
const gearCount = getRandomNumber(2, 4)
const outlineCircleCount = getRandomNumber(2, 4)
const filledCircleCount = getRandomNumber(1, 2)
const horizontalPosRandom = getRandomNumber(0, 1)

const AnimatedContent = ({ children }) => {
  return (
    <div>
      {Array.apply(null, Array(outlineCircleCount)).map(() => (
        <>
          <OutlineCircle
            delay={`${getRandomNumber(0, 5)}`}
            duration={`${getRandomNumber(5, 30)}s`}
            top={
              horizontalPosRandom === 0 ? `${getRandomNumber(10, 90)}vh` : ''
            }
            bottom={
              horizontalPosRandom === 1 ? `${getRandomNumber(10, 90)}vh` : ''
            }
            left={`${getRandomNumber(10, 90)}vw`}
            size={`${getRandomNumber(20, 250)}`}
          />
        </>
      ))}

      {Array.apply(null, Array(gearCount)).map(() => (
        <>
          <Gear
            top={
              horizontalPosRandom === 0 ? `${getRandomNumber(10, 90)}vh` : ''
            }
            bottom={
              horizontalPosRandom === 1 ? `${getRandomNumber(10, 90)}vh` : ''
            }
            left={`${getRandomNumber(10, 90)}vw`}
            size={`${getRandomNumber(20, 150)}`}
          />
        </>
      ))}

      {Array.apply(null, Array(filledCircleCount)).map(() => (
        <>
          <FilledCircle
            top={`${getRandomNumber(10, 90)}vh`}
            left={`${getRandomNumber(10, 90)}vw`}
            size={`${getRandomNumber(20, 150)}px`}
            duration={`${getRandomNumber(3, 8)}s`}
          />
          <FilledCircle
            bottom={`${getRandomNumber(10, 90)}vh`}
            left={`${getRandomNumber(10, 90)}vw`}
            size={`${getRandomNumber(20, 150)}px`}
            duration={`${getRandomNumber(3, 8)}s`}
          />
        </>
      ))}

      <main className="main">{children}</main>
    </div>
  )
}

AnimatedContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AnimatedContent
